import { Avatar, Box, Typography } from '@mui/material';
import { unstable_useForkRef as useForkRef } from '@mui/utils';
import { DateRange } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Iconify from '@src/components/iconify/Iconify';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import { useUsersContext } from './provider';

export default function DateFilter(props: any) {
	const { state } = useUsersContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState<DateRange<Dayjs>>([
    null,
    null,
  ]);

  const toggleOpen = () => setIsOpen((currentOpen) => !currentOpen);

  const handleOpen = () => setIsOpen(true);

  const handleClose = () => setIsOpen(false);

  const onChange = (value: any) => {
    if(!value.includes(null)){
      setValue(value);
      props.onChange(value);
    }
  }

  React.useEffect(() => {
    setValue([
      state.fromDate ? dayjs(state.fromDate) : null,
      state.toDate ? dayjs(state.toDate) : null,
    ]);
  }, [state.fromDate, state.toDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['SingleInputDateRangeField']}>
        <DateRangePicker
          value={value}
          open={isOpen}
          onClose={handleClose}
          onOpen={handleOpen}
          onChange={onChange}
          slots={{ field: BrowserField }}
          slotProps={{
            field: {
              onAdornmentClick: toggleOpen,
            } as any,
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

interface BrowserFieldProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: React.ReactNode;
  inputRef?: React.Ref<any>;
  InputProps?: {
    ref?: React.Ref<any>;
    endAdornment?: React.ReactNode;
    startAdornment?: React.ReactNode;
  };
  value: any[];
  error?: boolean;
  focused?: boolean;
  ownerState?: any;
  sx?: any;
  onAdornmentClick?: () => void;
  enableAccessibleFieldDOMStructure: boolean;
}

const BrowserField = React.forwardRef(
  (props: BrowserFieldProps, ref: React.Ref<HTMLDivElement>) => {
    const {
      id,
      value,
      InputProps: { ref: containerRef } = {},
      onAdornmentClick,
    } = props;
    const handleRef = useForkRef(containerRef, ref);

    return (
      <Box
        onClick={onAdornmentClick}
        sx={[
          {
            display: 'flex',
            width: 256,
            height: 56,
            gap: 1,
            bgcolor: "white",
            borderRadius: "10px",
            alignItems: 'center',
            flexDirection: "row",
            justifyContent: "center",
          },
        ]}
        id={id}
        ref={handleRef}
      >
        <Avatar sx={{ bgcolor: "rgba(45, 156, 219, 0.15)" }}>
          <Iconify
            icon={"lucide:calendar"}
            color="#2D9CDB"
            width={22}
          />
        </Avatar>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant='body1' fontWeight={"600"} >Filter Period</Typography>
          <Typography variant='body1' fontSize={10} >
            {value[0]?.format("DD MMM YYYY")} - {value[1]?.format("DD MMM YYYY")}
          </Typography>
        </Box>
        <Iconify
          icon={"ic:baseline-expand-more"}
          color="#B9BBBD"
          width={25}
        />
      </Box>
    );
  },
);