import { Box, Typography } from '@mui/material';

import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import {
	Bar,
	CartesianGrid,
	ComposedChart,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { usePlatformToolsServices } from './hooks';
import PositionedMenu from './menu';
import { CustomYAxisLabel } from './subscription-number';

const TotalNumberOfPurchase = () => {
	const [period, setPeriod] = useState("MONTHLY");
	const { purchaseChart, refetchPurchaseChart } = usePlatformToolsServices({period});

	const barSize = useMemo(() => {
		switch(period){
			case "DAILY":
				return 11;
			case "THIS_WEEK":
				return 62;
			case "MONTHLY":
				return 25;
			case "YEARLY":
				return 100;
		}
	},[period]);

	useEffect(() => {
		if(period){
			refetchPurchaseChart();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[period]);

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
				Total Number of Purchases
			</Typography>
			<Box 
				p={"20px"}
				borderRadius={"25px"} 
				display={"flex"}
				width={"100%"}
				flexDirection={"column"}
				height={350}
				bgcolor={"white"}>
				<PositionedMenu value={period} handleChange={(value: string) => setPeriod(value)} />
				<ResponsiveContainer width="100%" height="94%">
					<ComposedChart
						data={purchaseChart?.data ?? []}
						margin={{
							top: 50,
							right: 10,
							bottom: 20,
							left: 10,
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis dataKey="label" height={25} />
						<YAxis>
							<Label
								content={
									<CustomYAxisLabel 
										label="Total number of purchases" 
										value={purchaseChart?.totalCount} />}
								angle={0}
								dx={0}
								dy={-12}
								position="insideLeft"
							/>
						</YAxis>
						<Tooltip />
						<Bar dataKey="value" barSize={barSize} fill="#84A3FF" />
					</ComposedChart>
				</ResponsiveContainer>
				{period === "DAILY" && <Typography variant="h6" alignSelf={"center"} color="#718EBF" >
					Month of {moment().format("MMMM")}
				</Typography>}
			</Box>
		</Box>
	)
}

export default TotalNumberOfPurchase