import { Container, Grid } from "@mui/material";
import { AppWidgetSummary } from "@src/sections/@dashboard/app";
import palette from '@src/theme/palette';
import { useUsersService } from "./hooks";
import { useUsersContext } from "./provider";
import { useNavigate } from "react-router-dom";
import Config from "@src/utils/api/config";

const { isFortune8 } = Config;

const WrappedHeader = () => {
	const navigate = useNavigate();
	const { handleReset, handleToggleUserDemographics,  } = useUsersContext();
	const { totalUsers, totalUsersThisMonth, totalActiveUsers } = useUsersService({
		fetchTotalUsers: true,
		fetchTotalUsersThisMonth: true,
		fetchTotalActiveUsers: true
	});

	return (
		<Container sx={{ marginBottom: 5 }}>
			<Grid container spacing={2.5}>
				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						title="Total No. of Active Users"
						value={totalActiveUsers}
						color={palette.goldenDream.dark}
						icon={'fa-solid:user-clock'}
						onClick={() => {
							handleReset();
							navigate('/dashboard/users?activeUsers=true');
						}}
					/>
				</Grid>

				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						disabled
						title="Total Users"
						value={totalUsers}
						color={palette.DarkTurquoise.dark}
						icon={'fa-solid:users'}
					/>
				</Grid>

				<Grid item xs={6} sm={6} md={3} >
					<AppWidgetSummary
						disabled
						title="New Users This Month"
						value={totalUsersThisMonth}
						color={palette.peach.dark}
						icon={'mdi:users-plus'}
					/>
				</Grid>

				{isFortune8 && (
					<Grid item xs={12} sm={6} md={3}>
						<AppWidgetSummary
							onClick={() => handleToggleUserDemographics()}
							noAmount
							title="Users Demographics"
							color={palette.violet.dark}
							labelProps={{
								sx: { fontSize: "15px", lineHeight: "19px", color: "#5922B8" },
							}}
							icon={'ic:baseline-equalizer'}
						/>
					</Grid>
				)}
			</Grid>
		</Container>
	)
}

const Header = () => {
	const { accessControl } = useUsersContext();
	const { allowDataDisplay } = accessControl || {};

	if (!allowDataDisplay) return null;

	return <WrappedHeader />;
}

export default Header