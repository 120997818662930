import { IconButton, InputAdornment } from "@mui/material";
import ButtonExport from "@src/components/buttons/button-export";
import Iconify from "@src/components/iconify";
import { UsersToolbarProps } from "@src/lib/types/users";
import { StyledRoot, StyledSearch, StyledWrap } from "@src/sections/@dashboard/styles.css";
import DateFilter from "./date-filter";
import { useUsersService } from "./hooks";
import { useUsersContext } from "./provider";

const Toolbar = ({ isLoading }: UsersToolbarProps) => {
  const { onDispatch } = useUsersContext();
	const { onRefreshUsers, onExportUsersList } = useUsersService();
	const { state, accessControl, handleSearch } = useUsersContext();

	const onChange = (value: any) => {
    onDispatch('fromDate')(value[0].format("YYYY-MM-DD"));
		onDispatch('toDate')(value[1].format("YYYY-MM-DD"));
  }

	return (
		<StyledRoot>
			<StyledSearch
				disabled={isLoading}
				value={state.search}
				onChange={(event) => handleSearch(event.target.value)}
				placeholder="Search User ID, Email or Display Name"
				startAdornment={
					<InputAdornment position="start">
						<Iconify
							icon="eva:search-fill"
							sx={{
								color: 'text.disabled',
								width: 20,
								height: 20
							}}
						/>
					</InputAdornment>
				}
			/>

			<StyledWrap>
				<DateFilter onChange={onChange} />
				<IconButton aria-label="delete" color="primary" onClick={onRefreshUsers}>
					<Iconify icon={'mdi:refresh'} width={30} />
				</IconButton>
				{accessControl?.allowExportUserData && <ButtonExport
					variant="text"
					disableRipple
					disableElevation
					sx={{
						color: "white",
						marginLeft: 1, px: 6, height: 54, 
						bgcolor: "purple.dark",
						"&:hover": {
							bgcolor: "purple.main"
						}
					}}
					onClick={() => onExportUsersList.mutate({})}
					loading={onExportUsersList.isPending}
				/>}
			</StyledWrap>
		</StyledRoot>
	)
}

export default Toolbar