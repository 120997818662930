import { IconButton, InputAdornment } from "@mui/material";
import ButtonExport from "@src/components/buttons/button-export";
import Iconify from "@src/components/iconify";
import { UsersToolbarProps } from "@src/lib/types/users";
import { StyledRoot, StyledSearch, StyledWrap } from "@src/sections/@dashboard/styles.css";

import DateFilter from "../date-filter";
import { useUsersService } from "../hooks";
import { useUsersContext } from "../provider";

const Toolbar = ({
	isLoading,
	handleRefresh
}: UsersToolbarProps) => {
	const {
		state,
		accessControl,
		handleSearch,
	} = useUsersContext();
	const { search } = state ?? {}
  const { onDispatch } = useUsersContext();
	const { onExportUsersSessionList } = useUsersService();

	const onChange = (value: any) => {
    onDispatch('fromDate')(value[0].format('YYYY-MM-DDTHH:mm:ss.SSSZ'));
		const updatedDate = new Date(value[1]); // Clone the date to avoid mutation
    updatedDate.setHours(23, 59, 0, 0); // 
		onDispatch('toDate')(updatedDate);
  }

	return (
		<StyledRoot sx={{flexWrap: "wrap"}}>
			<StyledSearch
				disabled={isLoading}
				value={search}
				onChange={(event) => handleSearch(event.target.value)}
				placeholder="Search.."
				startAdornment={
					<InputAdornment position="start">
						<Iconify
							icon="eva:search-fill"
							sx={{
								color: 'text.disabled',
								width: 20,
								height: 20
							}}
						/>
					</InputAdornment>
				}
			/>

			<StyledWrap >
				<DateFilter onChange={onChange} />
				<IconButton aria-label="delete" color="primary" onClick={handleRefresh}>
					<Iconify icon={'mdi:refresh'} width={30} />
				</IconButton>
				{accessControl?.allowExportUserData && <ButtonExport
					sx={{
						marginLeft: 1, px: 6, height: 54, 
						bgcolor: "purple.dark",
						"&:hover": {
							bgcolor: "purple.main"
						}
					}}
					onClick={() => onExportUsersSessionList.mutate({})}
					loading={onExportUsersSessionList.isPending}
				/>}
			</StyledWrap>
		</StyledRoot>
	)
}

export default Toolbar