import { Box, Typography } from '@mui/material';
import _sortBy from 'lodash/sortBy';

import useCoinTransactionBetStats from '@src/hooks/useCoinTransactionBetStatsService';
import { useMemo, useState } from 'react';
import {
	Area,
	Bar,
	CartesianGrid,
	ComposedChart,
	Label,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';
import { CustomYAxisLabel } from '@src/pages/platform-tools/overview/subscription-number';
import PositionedMenu from '@src/pages/platform-tools/overview/menu';

const UserEngagementsChart = () => {
	const [period, setPeriod] = useState("MONTHLY");
	const { data } = useCoinTransactionBetStats(period);

	const barSize = useMemo(() => {
		switch(period){
			case "DAILY":
				return 11;
			case "THIS_WEEK":
				return 62;
			case "MONTHLY":
				return 25;
			case "YEARLY":
				return 100;
		}
	},[period]);

	return (
		<Box mt={5}>
			<Typography variant="h5" sx={{ mt: 5, mb: 2 }}>
				Engagement Activity
			</Typography>
			<Box 
				p={"20px"} borderRadius={"25px"} display={"flex"}
				width={"100%"}
				height={350}
				flexDirection={"column"}
				bgcolor={"white"}>
				<PositionedMenu value={period} handleChange={(value: string) => setPeriod(value)} />
				<ResponsiveContainer width="100%" height="100%">
					<ComposedChart
						data={data?.data ?? []}
						margin={{
							top: 50,
							right: 10,
							bottom: 20,
							left: 10,
						}}>
						<CartesianGrid stroke="#f5f5f5" />
						<XAxis dataKey="label" height={25} />
						<YAxis>
							<Label
								content={
									<CustomYAxisLabel 
										label="Total number of users" 
										value={data?.totalCount} />}
								angle={0}
								dx={0}
								dy={-12}
								position="insideLeft"
							/>
						</YAxis>
						<Tooltip />
						<Bar dataKey="value" barSize={barSize} fill="#84A3FF" />
					</ComposedChart>
				</ResponsiveContainer>
				
			</Box>
		</Box>
	)
}

export default UserEngagementsChart